import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";

import { initDomain, useBaasClient } from "react-baas";

initDomain({
  domain: "omars.dev",
});

function App() {
  const { loading } = useBaasClient({
    route: "/",
  });

  const isFirstRun = useRef(true);

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [player, setPlayer] = useState(false);

  const [fontSize, setFontSize] = useState(window.innerWidth < 720 ? 40 : 60);

  const handleTime = (intervalId) => {
    const targetDate = new Date("2024-04-24T00:00:00");
    const currentDate = new Date();
    const remainingTime = targetDate - currentDate;

    if (remainingTime < 0) {
      clearInterval(intervalId);
      return;
    }

    setDays(Math.floor(remainingTime / (1000 * 60 * 60 * 24)));
    setHours(
      Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    );
    setMinutes(Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60)));
    setSeconds(Math.floor((remainingTime % (1000 * 60)) / 1000));
  };

  useEffect(() => {
    let intervalId;

    if (isFirstRun.current) {
      handleTime(intervalId);
      isFirstRun.current = false;
    }

    setInterval(() => {
      handleTime(intervalId);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 720) {
        setFontSize(40);
      } else {
        setFontSize(60);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const clickMeHandler = async () => {
  //   const { data, error } = await sendForm({
  //     email: "me@omars.dev",
  //     phoneNumber: "12121212",
  //     message: "12121212",
  //   });
  // };

  return (
    !loading && (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100vw",
            backgroundColor: "#1a1a1a",
            flexDirection: "column",
          }}
        >
          <ReactPlayer
            url="https://youtu.be/jfKfPfyJRdk"
            playing={player}
            controls={false}
            width={0}
            height={0}
          />
          <h1 style={{ color: "rgba(210, 210, 210)", fontSize: fontSize }}>
            {days} : {hours} : {minutes} : {seconds}
          </h1>
          <h3
            style={{ color: "rgba(210, 210, 210)", cursor: "pointer" }}
            onClick={() => setPlayer((old) => !old)}
          >
            It's The Final Count Down
          </h3>
          {/* <button onClick={clickMeHandler}>Click me</button> */}
        </div>
      </>
    )
  );
}

export default App;
